import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import {
  QuestionTypes,
  TQuestionnaire,
  TQuestionnaireQuestion,
  TQuestionnaireQuestionAnswerOption
} from '@/_types/questionnaire.type';

export type TGetEventQuestionnairesParams = {
  eventId: number;
}

export type TGetProgramQuestionnairesParams = {
  eventId: number;
  programId: number;
}

export type TCreateQuestionnaireParams = {
  eventId: number;
  programId: number;
  title: string;
}

export type TEditQuestionnaireParams = {
  eventId: number;
  questionnaireId: number;
  title: string;
}

export type TDeleteQuestionnaireParams = {
  eventId: number;
  questionnaireId: number;
}

export type TCreateQuestionnaireQuestionParams = {
  eventId: number;
  questionnaireId: number;
  text: string;
  questionType: QuestionTypes;
  isEnabled: boolean;
}

export type TDeleteQuestionnaireQuestionParams = {
  eventId: number;
  questionnaireId: number;
  questionId: number;
}

export type TEditQuestionnaireQuestionParams = {
  eventId: number;
  questionnaireId: number;
  questionId: number;
  text: string;
  questionType: QuestionTypes;
  isEnabled: boolean;
}

export type TCreateQuestionnaireAnswerOptionParams = {
  eventId: number;
  questionnaireId: number;
  questionId: number;
  text: string;
}

export type TDeleteQuestionnaireAnswerOptionParams = {
  eventId: number;
  questionnaireId: number;
  questionId: number;
  answerOptionId: number;
}

export type TPutQuestionnaireAnswerParams = {
  eventId: number;
  questionnaireId: number;
  questionId: number;
  answerOptionId: number;
  freeTextAnswer?: string;
}

export class QuestionnairesApi extends BaseApi {

  public async getEventQuestionnaires(params: TGetEventQuestionnairesParams, cancelToken?: CancelToken): Promise<TQuestionnaire[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/questionnaire/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TQuestionnaire>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async getProgramQuestionnaires(params: TGetProgramQuestionnairesParams, cancelToken?: CancelToken): Promise<TQuestionnaire[]> {
    const { eventId, programId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/program/${programId}/questionnaire/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TQuestionnaire>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List;
  }

  public async createQuestionnaire(params: TCreateQuestionnaireParams): Promise<TQuestionnaire> {

    const { eventId, programId, title } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/program/${programId}/questionnaire`,
      method: 'POST',
      params: {
        title
      }
    };

    let response: AxiosResponse;
    try {
      response = await this.axios.request<TQuestionnaire>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async editQuestionnaire(params: TEditQuestionnaireParams): Promise<boolean> {

    const { eventId, questionnaireId, title } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}`,
      method: 'PATCH',
      params: {
        title
      }
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

  public async deleteQuestionnaire(params: TDeleteQuestionnaireParams): Promise<void> {
    const { eventId, questionnaireId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async createQuestion(params: TCreateQuestionnaireQuestionParams): Promise<TQuestionnaireQuestion> {
    const { eventId, questionnaireId, text, questionType, isEnabled } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}/question`,
      method: 'POST',
      params: {
        text: text,
        question_type: (questionType as string),
        is_enabled: isEnabled,
      }
    };

    let response: AxiosResponse;
    try {
      response = await this.axios.request<TQuestionnaireQuestion>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async editQuestion(params: TEditQuestionnaireQuestionParams): Promise<boolean> {

    const { eventId, questionnaireId, questionId, text, questionType, isEnabled } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}/question/${questionId}`,
      method: 'PATCH',
      params: {
        text,
        question_type: (questionType as string),
        is_enabled: isEnabled,
      }
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

  public async deleteQuestion(params: TDeleteQuestionnaireQuestionParams): Promise<void> {

    const { eventId, questionnaireId, questionId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}/question/${questionId}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async createAnswerOption(params: TCreateQuestionnaireAnswerOptionParams): Promise<TQuestionnaireQuestionAnswerOption> {
    const { eventId, questionnaireId, questionId, text } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}/question/${questionId}/answer_option`,
      method: 'POST',
      params: {
        text
      }
    };

    let response: AxiosResponse;
    try {
      response = await this.axios.request<TQuestionnaireQuestionAnswerOption>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async deleteAnswerOption(params: TDeleteQuestionnaireAnswerOptionParams): Promise<void> {

    const { eventId, questionnaireId, questionId, answerOptionId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}/question/${questionId}/answer_option/${answerOptionId}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async putAnswer(params: TPutQuestionnaireAnswerParams): Promise<void> {

    const { eventId, questionnaireId, questionId, answerOptionId, freeTextAnswer } = params;

    const queryParams: any = {
      answer_option_id: answerOptionId,
    };

    if (freeTextAnswer) {
      delete queryParams.answer_option_id;
      queryParams.answer = freeTextAnswer;
    }
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/questionnaire/${questionnaireId}/question/${questionId}/answer`,
      method: 'PUT',
      params: queryParams
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

}

const questionnairesApi = new QuestionnairesApi();
export default questionnairesApi;
