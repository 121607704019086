<template>
  <div class="head-nav">
    <biletumSharer :url="shareLink" :position="{t:0,l:0}"
                   ref="biletumSharer"></biletumSharer>
    <div class="btns-group" v-if="leftNav || back || showCloseButton">
      <template v-if="showCloseButton">
        <a class="cur-p" @click="close()">
          <icon-close-circle-outline/>
        </a>
      </template>
      <template v-else-if="back">
        <router-link :to="back" class="router-link-back">
          <div class="icon-container">
            <icon-arrow-left></icon-arrow-left>
          </div>
          <div class="label">
            {{ backLabel || $t('button.back') }}
          </div>
        </router-link>
      </template>

      <!--      <a href="javascript:void(0);">-->
      <!--        <iconZoomOut/>-->
      <!--      </a>-->
    </div>
    <div class="btns-group">
      <!--<a :href="setUrl()"
          id="video_stream"
          :target="target">
        <icon-videocam :color="streamColor"/>
      </a>-->
      <a href="javascript:void(0);"
         @click="goToMessages()"
      >
        <icon-message/>
      </a>
      <a href="javascript:void(0);"
         @click.stop="share()"
         @mouseover="setShareColor('#57C7FF')"
         @mouseleave="setShareColor('#707070')">
        <icon-share :color="shareColor"/>
      </a>
      <a href="javascript:void(0);"
         @click="toggleFavorite()">
        <i class="material-icons">
          <icon-star :color="color" :active="isActiveIcon"/>
        </i>
      </a>
    </div>
  </div>
</template>

<script>
import iconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
// import iconZoomOut from '../../../svg/icons/IconZoomOut';
// import iconVideocam from '../../../svg/icons/Icon-videocam';
import iconMessage from '@/_modules/icons/components/icon-message.vue';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import IconStar from "@/_modules/icons/components/icon-star.vue";
import biletumSharer from "../../popups/biletumSharer"
import UrlHelper from '@/_helpers/url.helper';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';

export default {
  name: 'contact-head-nav',
  inheritAttrs: true,
  components: {
    IconStar,
    iconCloseCircleOutline,
    // iconZoomOut,
    // iconVideocam,
    iconMessage,
    iconShare,
    biletumSharer,
    IconArrowLeft
  },
  computed: {},
  watch: {
    isFavoriteContact: {
      immediate: true,
      handler() {
        this.favorite = this.isFavoriteContact;
        this.setFavColor();
      }
    },
    video_stream_url: {
      immediate: true,
      handler() {
        if (this.video_stream_url) {
          this.videoStream = this.video_stream_url;
        } else {
          this.videoStream = '';
        }
      }
    },
  },
  props: {
    leftNav: {
      type: Boolean,
      default: false,
    },
    isFavoriteContact: {
      default: false,
      required: true,
    },
    video_stream_url: {
      default: ''
    },
    back: {
      type: Object,
    },
    backLabel: {
      type: String,
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      favorite: false,
      color: '',
      isActiveIcon: false,
      videoStream: '',
      streamColor: '',
      shareColor: '#707070',
      target: '',
      shareLink: '',
    }
  },
  mounted() {
    /* Когда компонент показан внутри страницы «Календарь встреч»,
       * открываем вкладку Messages
       */
    if (this.$route.name === 'promo-page-calendar-contact') {
      this.goToMessages();
    }
  },
  methods: {
    close() {
      let targetRouteName = 'promo-contacts';
      if (this.$route.name === 'promo-page-cabinet-meetings-contact') {
        targetRouteName = 'promo-page-cabinet-meetings';
      } else if (this.$route.name === 'text-chat-expanded') {
        this.$router.push({
          name: 'text-chat-expanded',
          params: {
            textChatId: this.$route.params.textChatId,
            contact_id: null
          },
        });
        return;
      }
      this.$router.push({
        name: targetRouteName,
        query: this.$route.query,
      })
    },
    toggleFavorite() {
      this.favorite = !this.favorite;
      this.$emit('promoFavoriteContact', this.favorite);
      this.setFavColor();
    },
    setFavColor() {
      if (this.favorite) {
        this.color = '#00b6f8';
        this.isActiveIcon = true;
      } else {
        this.color = '#000';
        this.isActiveIcon = false;
      }
    },
    goToMessages() {
      this.$emit('promoContactGoToMessages')
    },
    setUrl() {
      if (this.videoStream) {
        this.streamColor = '#00b6f8';
        // let videoStream = document.getElementById('video_stream');
        // videoStream.setAttribute('target', '_blank');
        this.target = '_blank';
        return UrlHelper.addMissingUrlSchema(this.videoStream);
      } else {
        this.streamColor = 'rgba(0,0,0,.25)';
        return 'javascript:;'
      }
    },
    setShareColor(color) {
      this.shareColor = color;
    },
    share() {
      this.shareLink = window.location.protocol + '//' + window.location.host + this.$route.path;
      this.$refs.biletumSharer.toggleSharer();
    }
  }

}

</script>

<style scoped lang="scss">
  .head-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .btns-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px;

      &:last-child {
        margin-left: auto;
      }

      .router-link-back {
        display: flex;
        flex-direction: row;
        width: 140px;
        justify-content: flex-start;

        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;

          /deep/ .icon {
            width: 24px;
          }
        }

        .label {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #000;
          margin-left: 7px;
        }
      }

      a {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 14px;
          color: $ew-black;
        }
      }
    }
  }

</style>
