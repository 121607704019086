<template>
  <div class="flex-row">
    <div
      v-if="!!contactToDisplay && isContactLoading"
      class="profile-wrapper profile-wrapper-skeleton"
    >
      <mu-circular-progress class="demo-circular-progress" :size="26">
      </mu-circular-progress>
    </div>
    <div class="profile-wrapper" v-if="contactToDisplay && !isContactLoading">

      <contact-head-nav
        :leftNav="false"
        :showCloseButton="isCloseContactButtonShown"
        :back="back"
        :back-label="backLabel"
        @promoFavoriteContact="toggleFavFromContactHeadNav"
        @promoContactGoToMessages="navSelect('messages')"
        :isFavoriteContact="contactToDisplay.is_favorite"
        :video_stream_url="contactToDisplay.video_stream_url"
      ></contact-head-nav>
      <div class="video-conf"></div><!-- TODO: remove it? -->
      <div class="profile">
        <div class="head-block">
          <div class="avatar avatar-headblock round-radius">
            <img :src="setAvatar(contactToDisplay.photo_url)" alt=""/>
          </div>
          <div class="head-content">
            <div class="title contact-title">
              <span v-if="contactToDisplay.name">{{ contactToDisplay.name + ' ' }}</span>
              <span v-if="contactToDisplay.surname">{{ contactToDisplay.surname }}</span>
              <span v-if="!contactToDisplay.name && !contactToDisplay.surname"> {{ $t('contacts.info.noname') }} </span>
            </div>
            <div class="subtitle contact-subtitle">{{ contactToDisplay.company_position }}</div>
            <div class="subtitle contact-subtitle">{{ contactToDisplay.company_name }}</div>
            <div class="subtitle contact-subtitle">{{ contactToDisplay.city }}<span
              v-if="contactToDisplay.city && contactToDisplay.country">,</span>
              {{ contactToDisplay.country }}
            </div>
          </div>
        </div>
        <div class="nav">
              <span
                v-if="!hideProfile"
                class="tab cur-p"
                :class="{ highlight: navSelected === 'profile' }"
                @click="navSelect('profile')"
              >{{ $t("title.profile") }}</span>
          <span
            class="tab cur-p"
            :class="{ highlight: navSelected === 'calendar' }"
            @click="navSelect('calendar')"
          >{{ $t("title.calendar") }}</span>
          <span
            class="tab cur-p"
            :class="{ highlight: navSelected === 'messages' }"
            @click="navSelect('messages')"
          >{{ $t("title.messages") }}</span>
          <span
            class="tab cur-p"
            :class="{ highlight: navSelected === 'notes' }"
            @click="navSelect('notes')"
          >{{ $t("title.notes") }}</span>
        </div>
        <div class="content">
          <div class="description ws-pre-wrap">
              <span v-if="contactToDisplay.company_description && navSelected === 'profile'">
                <truncate
                  action-class="read-more"
                  :length="90"
                  :less="$t('button.less')"
                  :clamp="$t('button.more')"
                  type="html"
                  :text="generateLinks(contactToDisplay.company_description)"
                ></truncate>
              </span>
          </div>
        </div>
      </div>

      <!-- Contact tags -->
      <div class="field-block field-block-contact-tags"
           v-if="contactToDisplay.tags && contactToDisplay.tags.length && navSelected === 'profile'">
        <div class="field-header">
          <div class="field-title">{{ $t('eventPage.edit.tagManagement.popupTitleContact') }}</div>
          <div class="action" v-if="contactToDisplay.tags && contactToDisplay.tags.length > 4">
            <a class="btn cur-p" @click="showAllTags = !showAllTags">
              <span v-if="showAllTags">{{ $t('button.less') }}</span>
              <span v-else>{{ $t('button.seeAll') }}</span>
            </a>
          </div>
        </div>
        <div class="field-content field-content-tags">
              <span class="tag" v-for="(tag, index) in contactToDisplay.tags"
                    :key="'contactCardContactTag_' + contactToDisplay.id + '_' + index"
                    v-show="index < 4 || showAllTags"
              >{{ tag.name }}</span>
        </div>
      </div>

      <!-- Documents -->
      <document-list
        class="field-block field-block-documents"
        :class="{
                'show-action': !showDocuments,
                'show-all': showDocuments
              }"
        :docs="contactToDisplay.files"
        :showAll="showDocuments"
        @seeAllDocuments="showAllDocuments()"
        v-if="contactToDisplay.files && contactToDisplay.files.length && (navSelected === 'profile' || navSelected === 'documents')"
      ></document-list>

      <!-- Notes -->
      <notes-list
        class="field-block field-block-notes"
        v-if="noteList && noteList.length && (navSelected === 'profile' || navSelected === 'notes')"
        :notes="noteList"
        :showAll="navSelected === 'notes'"
        :showActionButton="navSelected !== 'notes'"
        @seeAllNotes="navSelect('notes')"
      ></notes-list>

      <div class="field-block"
           v-if="navSelected === 'calendar'"
      >
        <div class="field-header">
          <div class="field-title">{{ $t('title.calendar') }}</div>
        </div>
        <div class="field-content field-content-meetings">
          <ContactSchedule
            :contactData="contactToDisplay"
            :promoPage="promoPage"
          ></ContactSchedule>
        </div>
      </div>
      <div class="message-block" v-if="navSelected === 'messages'">
        <div class="field-block">
          <div class="field-header">
            <div class="field-title">{{ $t('title.messages') }}</div>
          </div>
        </div>
        <messages
          ref="msgContainer"
          :current-contact="contactToDisplay"
          :contact-id="contactToDisplay.id"
        ></messages>
      </div>

    </div>
    <router-view
      v-if="$route.name !== 'promo-page-cabinet-meetings-contact'"
      class="contact-company-wrapper"
      name="contactCompanyRouterView"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import contactHeadNav from './contact-head-nav';
import truncate from 'vue-truncate-collapsed';
import { FileNameHelper } from '@/helpers/filename.helper';
import DocumentList from '../documents/documentList';
import NotesList from '../notes/notesList';
import Statistics from '@/services/statistics';
import ContactSchedule from '@/_modules/contacts/components/contact-schedule/contact-schedule.vue'
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import messages from '@/_modules/promo/components/messages/messages.vue';
const beforeRouteEnter = { to: null, from: null };
const DIRECT_MESSAGE_MAX_LENGTH = 1000;
export const MESSAGES_PAGE_LENGTH = 50;

export default {
  name: 'promo-contact',
  components: {
    contactHeadNav,
    truncate,
    DocumentList,
    NotesList,
    ContactSchedule,
    messages
  },
  props: {
    hideProfile: {
      type: Boolean,
      default: false
    },
    back: {
      type: Object,
    },
    backLabel: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      promoPage: state => state.promoStore.promoPage,
      navigationTab: state => state.promoStore.navigationTab,
      noteListAll: state => state.noteStore.noteList,
      eventInfo: state => state.eventStore.eventInfo,
    }),
    ...mapGetters({
      contact: 'promoPageStore/contact',
      contactById: 'contactsStore/contactById',
      getMessagesStateByUserId: '_messageStore/getMessagesStateByUserId',
    }),
    isDirectMessageLengthExceeded() {
      return this.newMessageText.length >= DIRECT_MESSAGE_MAX_LENGTH;
    },
    messagesState() {
      if (!this.userIdOfDisplayedContact) {
        return null;
      }
      return this.getMessagesStateByUserId(this.userIdOfDisplayedContact);
    },
    messageListAll() {
      const messagesState = this.messagesState;
      return (messagesState && messagesState.data) || null;
    },
    isCloseContactButtonShown() {
      let result = false;
      if (this.$route.name === 'promo-page-contacts-contact'
        || this.$route.name === 'promo-contact-company'
        || this.$route.name === 'promo-page-cabinet-meetings-contact'
        || this.$route.name === 'text-chat-expanded'
      ) {
        result = true;
      }
      return result;
    },
    contactId() {
      return (this.$route.params.contact_id && parseInt(this.$route.params.contact_id)) || null;
    },
    contactToDisplay() { // returns TContact | null
      const contactId = this.contactId;
      if (!this.contactById || !contactId) {
        return null;
      }
      return this.contactById(contactId);
    },
    userIdOfDisplayedContact() {
      const contactToDisplay = this.contactToDisplay;
      return (contactToDisplay && contactToDisplay.user && contactToDisplay.user.id) || null;
    },
  },
  watch: {
    contactId: {
      immediate: true,
      handler() {
        const contactId = this.contactId;
        if (contactId && !this.contactById(contactId)) {
          this.$store.dispatch('contactsStore/requestContact', { contactId });
        }

        if (!contactId) {
          return;
        }
        Statistics.contactView({
          eventId: this.event_id,
          contactId: contactId,
        }, beforeRouteEnter);

        if (this.$route.name !== 'promo-page-cabinet-meetings-contact') {
          this.showContactCompany(contactId);
        }
        if (this.contact && this.contact.user) {
          this.$store.dispatch('_messageStore/clearUserMessages', { userId: this.contact.user.id });
        }
        this.messageList = [];
        this.navSelect(this.navSelected)
      },
    },
    userIdOfDisplayedContact: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$store.dispatch('_messageStore/clearUserMessages', { userId: newVal });
          this.messageList = [];
        }
        this.isFirstMessagesPageRequest = true;
        this.dispatchLoadingTabContents();
      },
    },
    navSelected: {
      immediate: true,
      handler() {
        this.markNoticedMessages();
        this.$emit('navSelected');
      }
    },
    navigationTab: {
      immediate: true,
      handler() {
        this.navSelect(this.navigationTab)
      }
    },
    messageListAll: {
      deep: true,
      handler() {
        this.messageList = (this.messageListAll && this.messageListAll.List) || [];
      }
    },
    promoPage: {
      deep: true,
      handler() {
        this.external_id = this.promoPage.external_id;
        if (
          this.external_id
          && this.$route.name !== 'promo-contact-company'
          && this.$route.name !== 'promo-page-calendar-contact'
          && this.$route.name !== 'promo-program-date-program-contact'
          && this.$route.name !== 'promo-page-cabinet-meetings-contact'
          && this.$route.name !== 'text-chat-expanded'
        ) {
          this.$router.push({
            name: 'promo-contact-company',
            params: {
              external_id: this.external_id
            },
            query: this.$route.query,
          }).catch(e => e);
        }
      }
    },
    isShowNotes: { // TODO: refactor
      handler() {
        this.showNotes = !this.isShowNotes;
      }
    },
    isShowDocuments: { // TODO: refactor
      handler() {
        this.showDocuments = !this.isShowDocuments;
      }
    },
    isShowMeetings: { // TODO: refactor
      handler() {
        this.showMeetings = !this.isShowMeetings;
      }
    },
  },
  data() {
    return {
      showAllTags: false,
      noteFav: true,
      leftNav: false,
      event_id: null,
      external_id: null,
      noteList: [],
      messageList: [],
      showDocuments: false,
      showNotes: false,
      isFavoriteContact: false,
      navSelected: 'profile',
      newMessageText: '',
      loadMessagesInterval: null,
      isMessageListLoading: false,
      isContactLoading: false,
      isFirstMessagesPageRequest: true,
      previousTopmostDirectMessageId: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    if (this.hideProfile) { // TODO: better naming of the prop
      this.navSelect('messages');
    }

    this.setComponentParameters();

    if (this.contactId && this.$route.name !== 'promo-page-cabinet-meetings-contact') {
      this.showContactCompany(this.contactId);
    }
  },
  updated() {
    this.setComponentParameters();
  },
  beforeDestroy() {
    this.$store.dispatch('promoStore/setNavigationTab', 'profile');
  },
  methods: {
    dateTimeString(date) {
      return this.$moment(date).format('lll');
    },

    setComponentParameters() {
      this.event_id = Number(this.$route.params.eventId);
      this.external_id = this.$route.params.external_id;
    },

    setMessageSenderAvatar(message) {
      // If the message is is_mine, take data from contact entity
      // otherwise from message.contact
      if (message.is_mine && this.contact && this.contact.photo_url) {
        return 'url(' + this.contact.photo_url + ')';
      } else if (message && message.contact && message.contact.photo_url) {
        return 'url(' + message.contact.photo_url + ')';
      }
      // fallback to default avatar
      return 'url(' + require('../../../../assets/images/no-avatar-60x60.svg') + ')';
    },

    showContactCompany(contact_id) {
      if (!this.external_id && (!this.contactToDisplay || !this.contactToDisplay.promopage_external_id)) {
        // AW-1372
        return;
      }
      if (contact_id) {
        this.isContactLoading = true;
        this.$store.dispatch('promoStore/getPromoPageContactId', {
          event_id: this.$route.params.eventId,
          contact_id: contact_id
        }).then(() => {
          this.isContactLoading = false;
        })
      }
    },

    docName(val) {
      return FileNameHelper.getFileName(val);
    },

    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },

    setNotesList() {
      this.noteList = [];
      if(!this.userIdOfDisplayedContact){return }
      this.$store.dispatch('noteStore/callNoteListAll',
        {
          event_id: this.$route.params.eventId,
          user_id: this.userIdOfDisplayedContact
        }).then(() => {
        if (this.noteListAll && this.noteListAll.List) {
          this.noteList = this.noteListAll.List
        }
      });
    },

    rememberTopmostMessageId() {
      this.previousTopmostDirectMessageId = (this.messageList.length ? this.messageList[0].id : 0);
    },

    markNoticedMessages() {
      if (this.navSelected !== 'messages' || !this.messageList || !this.messageList.length) {
        return;
      }

      const messageIds = this.messageList.map(message => message.id);
      this.$store.dispatch('notificationsStore/setNoticedMessages', messageIds);
      this.$store.dispatch('contactsStore/clearContactUnreadMessages', this.contactId);
    },

    setAvatar(url) {
      if (url) {
        return url
      } else {
        return require('../../../../assets/images/no-avatar-60x60.svg');
      }
    },

    showAllDocuments() {
      this.showDocuments = !this.showDocuments
    },

    showAllNotes() {
      this.showNotes = !this.showNotes
    },

    toggleFavFromContactHeadNav(value) {

      this.isFavoriteContact = value;

      const payload = {
        eventId: this.event_id,
        contactId: this.contactId,
      };

      if (value === true) {
        this.$store.dispatch('contactsStore/addFavContact', payload);
      } else {
        this.$store.dispatch('contactsStore/removeFavContact', payload);
      }

    },

    navSelect(value) {
      this.navSelected = value || 'profile';

      this.dispatchLoadingTabContents();

      if (this.navSelected === 'profile') {
        this.setNotesList();
      }
    },

    dispatchLoadingTabContents() {
      if (this.navSelected === 'notes') {
        this.setNotesList();
      }
    },

    generateLinksWithImagePreviews(text) {
      return ChatHelper.createLinksWithImagePreviews(text)
    },

    generateLinks(text) {
      return ChatHelper.createLinks(text)
    }
  },
};
</script>

<style scoped lang="scss">
.contact-company-wrapper {
  width: calc(100% - 410px); // AW-734 100% - ширина колонки с профилем - щель между колонками
  min-width: 392px; // AW-734
}

.scroll-height {
  max-height: 350px;
  padding-bottom: 30px;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.profile-wrapper {
  width: 392px;
  min-width: 392px;
  height: auto;
  box-shadow: 5px 5px 15px #3754aa1a;
  border-radius: 10px;
  background-color: $ew-white;
  z-index: 1;
  margin-right: 16px;
}

.meetings-page .profile-wrapper {
  width: 100%;
}

.field-block {
  padding: 10px 0;
  position: relative;
  overflow: hidden;

  &-documents {
    padding-left: 25px;
    padding-right: 25px;
  }

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 8px;

    .field-title {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      color: $ew-black;
      opacity: 0.8;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        left: -300%;
        bottom: 2px;
        content: "";
        border-bottom: 1px solid #eaeaea;
        width: 300%;
      }
    }

    .btn {
      text-align: left;
      font-size: 12px;
      color: $ew-blue;
    }
  }

  .enumerable {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    overflow: hidden;
    height: 100px;
    flex: 1;
  }
}

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
}

.field-content {
  padding: 16px 0;

  &-meetings {
    box-shadow: none;
    border-radius: 0;
    padding: 10px 25px 25px;
    background-color: transparent;
  }

  .doc {
    .doc-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 33px;
    }
  }

  .square-radius {
    border-radius: 5px;
  }

  .round-radius {
    border-radius: 50%;
  }

  .avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .avatar-documenticon {
    width: 37px;
    height: 37px;
    min-width: 37px;
  }

  .title {
    margin-top: 6px;
  }

  .title,
  .subtitle {
    text-align: left;
    font-weight: 300;
    font-size: 8px;
    color: $ew-black;
    opacity: 0.8;
  }
}

.profile {
  padding: 0 25px;
  margin-bottom: 2rem;

  &::before {
    display: block;
    content: "";
    height: 1px;
    width: 25px;
    background-color: $ew-gray-lightest;
    position: relative;
    top: 0;
    left: -25px;
  }

  .head-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .square-radius {
      border-radius: 5px;
    }

    .round-radius {
      border-radius: 50%;
    }

    .avatar {
      width: 90px;
      height: 90px;
      min-width: 90px;
      opacity: 1;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .head-content {
      padding-left: 20px;
      overflow: hidden;

      .contact-title,
      .contact-subtitle {
        max-width: 100%;
      }
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 25px 0 0;

    .tab {
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      opacity: 0.8;
      color: $ew-black;
      text-decoration: none;
    }

    .highlight {
      border-bottom: 1px solid $ew-blue;
      color: $ew-blue;
    }
  }

  .content {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .description {
      font-size: 12px;
      line-height: 14px;
      text-align: left;
    }
  }
}

html[lang=ru] .nav a {
  font-size: 12px;
}

.content-item {
  margin-bottom: 26px;
  width: 20%;

  &:last-child {
    margin-right: 0;
  }
}

.show-all {
  height: auto !important;
}

.fav {
  margin-left: auto;
}

.field-block-notes {
  padding-left: 25px;
  padding-right: 25px;
}

.profile-wrapper-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.inner-circular-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages {
  padding: 0 25px;
}

.field-block-contact-tags {
  .field-content {
    padding: 0 12px;
    display: flex;
    flex-flow: row wrap;
  }

  .action .btn {
    font-size: 12px;
  }

  .tag {
    border-radius: 99px;
    padding: 7px 12px;
    margin: 0 5px 5px 0;
    border-style: solid;
    border-width: 1px;
    border-color: $ew-gray-weak;
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/deep/ .message-block {

  .messages-list {
    padding-bottom: 0;
  }

  .messages-list-scroll {
    position: relative;
    max-height: 980px;
    min-height: 392px;
  }

  .new-message-form {
    position: relative;
    width: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .message {
    padding: 0 20px;
  }

  .message-show-day-title {
    padding-top: 58px;
  }
}
</style>

<style lang="scss">
.read-more {
  font-size: 12px;
  color: $ew-blue !important;
  text-decoration: none;
  cursor: pointer;
}

.more-btn {
  text-align: left;
  font-size: 12px;
  color: $ew-blue !important;
}

</style>
